<template>
  <section class="info__section w-full" v-if="pizzaOfTheMonth.image">
    <section class="info__content">
      <div class="image__wrapper">
        <img
          :src="pizzaOfTheMonth.image.fields.file.url"
          class="unselectable lazyload"
          :alt="pizzaOfTheMonth.name"
        />
      </div>
      <div
        class="info__description w-full h-full flex justify-center items-center"
      >
        <div class="info__description--content">
          <h1 class="h1" data-aos="zoom-y-out" data-aos-delay="100">
            Spróbuj pizzę miesiąca!
          </h1>
          <h2 class="h2" data-aos="zoom-y-out" data-aos-delay="600">
            {{ pizzaOfTheMonth.name }}
            <span v-if="pizzaOfTheMonth.price" class="price">
              {{ pizzaOfTheMonth.price }} zł
            </span>
          </h2>
          <p data-aos="zoom-y-out" data-aos-delay="800">
            {{ pizzaOfTheMonth.description }}
          </p>
          <div
            class="button__wrapper mt-4 lg:mt-8"
            data-aos="zoom-y-out"
            data-aos-delay="800"
          >
            <a
              href="https://pizzasmolec.pl"
              target="_blank"
              ref="noopener"
              class="uppercase px-4 py-2 bg-green border-0 text-gray-900 text-sm hover:bg-gray-900 hover:text-green text-center font-bold tracking-widest"
            >
              Sprawdź pizze
            </a>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";
export default defineComponent({
  name: "PizzaOfTheMonth",
  setup() {
    const store = useStore();
    store.dispatch("fetchPizzaOfTheMonth");

    const pizzaOfTheMonth = computed(() => store.getters.pizzaOfTheMonth);
    return { pizzaOfTheMonth };
  }
});
</script>

<style scoped>
.price {
  background-color: #494d51;
  color: white;
  height: 100%;
  padding: 0 0.5rem;
}
</style>
