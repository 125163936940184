<template>
  <section class="instagram instafeed text-gray-200">
    <div
      class="instagram__container flex flex-col items-center justify-center md:min-h-0 mx-auto py-10 px-6 md:py-16 md:px-36 text-center"
    >
      <h2
        class="h2 md:text-3xl mb-4"
        data-aos="zoom-y-out"
        data-aos-delay="400"
      >
        Zaobserwuj nas na Instagramie
        <a
          href="https://www.instagram.com/tenisplanetsmolec/"
          target="_blank"
          class="cursor-pointer hover:text-green"
          rel="noopener"
        >
          @tenisplanetsmolec
        </a>
      </h2>
      <p
        class="text-xl text-gray-200"
        data-aos="zoom-y-out"
        data-aos-delay="400"
      >
        Zainspiruj się miłością do gotowania.
      </p>
      <div
        class="images__grid mt-10"
        id="instafeed"
        data-aos="zoom-y-out"
        data-aos-delay="400"
      ></div>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import axios from "axios";

interface ImageObject {
  mediaUrl: string;
  permalink: string;
}

export default defineComponent({
  name: "Testimonials",
  data() {
    return {
      images: [] as ImageObject[]
    };
  },
  async created() {
    const userId = process.env.VUE_APP_userId;
    const userSecret = process.env.VUE_APP_userSecret;
    const url = `https://ig.instant-tokens.com/users/0c47812d-ede8-4d2b-a4b7-6ee77f96811d/instagram/${userId}/token?userSecret=${userSecret}`;
    let token = "";

    await axios
      .get(url)
      .then(response => (token = response.data.Token))
      .catch(e => console.log(e));

    // Instafeed doesn't have any types.
    // eslint-disable @typescript-eslint/no-explicit-any
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    // eslint-disable-next-line no-undef
    const feed = new Instafeed({
      accessToken: token,
      limit: 4,
      template:
        '<a href="{{link}}" aria-label="instagram" rel="noopener" class="post" target="_blank"><img class="image" alt="{{caption}}" src="{{image}}" /></a>'
    });
    feed.run();
  }
});
</script>

<style lang="scss">
.instagram {
  background-image: url("../assets/images/backgrounds/darkbg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.instagram__container {
  #instafeed {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
    grid-auto-rows: 1fr;
    grid-gap: 3vh;
    &::before {
      content: "";
      width: 0;
      padding-bottom: 100%;
      grid-row: 1 / 1;
      grid-column: 1 / 1;
    }
    > *:first-child {
      grid-row: 1 / 1;
      grid-column: 1 / 1;
    }
    a {
      img {
        width: 100%;
        height: 100%;
        transition: all 0.15s ease-in-out;
        object-fit: cover;
      }
    }
    // .post:hover .image {
    //   transition: all 0.5s ease-in-out;
    //   -webkit-transform: scale(1.03) translate3d(0, 0, 0);
    //   transform: scale(1.03) translate3d(0, 0, 0);
    // }
  }
  @media (min-width: 768px) {
    #instafeed {
      grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
    }
  }
  @media (min-width: 1024px) {
    padding: 8vh;
    #instafeed {
      grid-template-columns: repeat(4, minmax(10rem, 1fr));
    }
  }
  @media screen and (min-width: 1024px) and (min-height: 1023px) and (max-height: 1024px) and (orientation: landscape) {
    #instafeed {
      grid-template-columns: repeat(2, minmax(12rem, 1fr));
    }
  }
  @media (min-width: 1024px) and (min-height: 1366px) {
    #instafeed {
      grid-template-columns: repeat(2, minmax(12rem, 1fr));
    }
  }
}
</style>
