
import { defineComponent } from "vue";

export default defineComponent({
  name: "StaticNav",
  props: {
    menuItems: Array,
    currentCategoryIndex: Number,
    swiperRef: Object
  },
  setup(props) {
    const handleSlide = (index: number) => {
      props.swiperRef?.slideTo(index, 600);
    };

    return { handleSlide };
  }
});
