
import { defineComponent, ref } from "vue";

interface Testimonial {
  author: string;
  photoUrl: string;
  opinion: string;
}

export default defineComponent({
  name: "Testimonials",
  setup() {
    const testimonials: Testimonial[] = [
      {
        author: "Pan Maciej",
        photoUrl: "testimonials5.jpg",
        opinion:
          "Super miejsce dla fanów gry w tenisa ziemnego - rewelacyjna, praktycznie nowa nawierzchnia na kortach, przystępne ceny oraz fajna liga i organizowane turnieje. Ogromnym plusem jest restauracja z barem, gdzie można usiąść po meczu, wypić piwo i zjeść pyszne jedzenie."
      },
      {
        author: "Pani Anna",
        photoUrl: "testimonials1.jpg",
        opinion:
          "Pizza na kruchym cieście, lunch dnia w atrakcyjnej cenie, jest mały kącik dla dzieci. Chętnie odwiedzę to miejsce jeszcze raz. Miła i sympatyczna obsługa, polecam."
      },
      {
        author: "Pani Katarzyna",
        photoUrl: "testimonials3.jpg",
        opinion:
          "Bardzo dobra pizza, przede wszystkim składniki wysokiej jakości, oliwa do pizzy, a nie sosy. Miła obsługa zarówno w lokalu, jak i dostawie. Zamawialiśmy już kilka razy i nigdy się nie zawiedliśmy."
      },
      {
        author: "Pan Michał",
        photoUrl: "testimonials2.jpg",
        opinion:
          "Korzystamy z Tenis Planet od kilku lat, a w międzyczasie z różnych diet pudełkowych i subskrypcji. Próbujemy specjałów codziennie w formie cateringu. Miło się patrzy, jak ta ekipa rośnie w siłę i jakość. Kilka lat temu głównie kawiarnia, następnie - bardzo dobra pizza."
      }
    ];

    const activeTestimonialIndex = ref(0);

    const changeTestimonial = () => {
      activeTestimonialIndex.value++;
      if (activeTestimonialIndex.value >= testimonials.length) {
        activeTestimonialIndex.value = 0;
      }
    };

    return { testimonials, activeTestimonialIndex, changeTestimonial };
  }
});
