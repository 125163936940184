<template>
  <section
    class="facebookSection w-full min-h-0 text-gray-200 max-w-6xl mx-auto px-4 sm:px-6"
  >
    <div
      class="facebook__container w-full h-full flex flex-col items-center justify-center md:min-h-0 mx-auto py-10 px-6 md:py-16 md:px-36"
    >
      <h2
        class="text-gray-900 h2 text-center mb-10"
        data-aos="zoom-y-out"
        data-aos-delay="400"
      >
        Nowości z naszego facebooka
      </h2>
      <div id="fb-root"></div>
      <div
        class="fb-page w-full h-full"
        data-href="https://www.facebook.com/tenisplanetsmolec/"
        data-tabs="timeline"
        data-width="500"
        data-height="700w"
        data-small-header="true"
        data-adapt-container-width="true"
        data-hide-cover="true"
        data-show-facepile="true"
        data-aos="zoom-y-out"
        data-aos-delay="400"
      >
        <blockquote
          cite="https://www.facebook.com/tenisplanetsmolec/"
          class="fb-xfbml-parse-ignore"
        >
          <a href="https://www.facebook.com/tenisplanetsmolec/" rel="noopener"
            >Tenis Planet Smolec</a
          >
        </blockquote>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "FacebookSection"
});
</script>

<style lang="scss">
.fb_iframe_widget iframe {
  position: static;
}
.fb_iframe_widget_fluid,
.fb_iframe_widget {
  display: flex !important;
  justify-content: center;
  align-items: center;
  span {
    width: 100% !important;
    iframe {
      position: static !important;
      margin: 0 auto;
    }
  }
}
</style>
