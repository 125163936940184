import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueScrollTo from "vue-scrollto";
import AOS from "aos";
import "aos/dist/aos.css";

import "./assets/styles/tailwind.scss";
import "./assets/styles/global.scss";

AOS.init({
  duration: 700,
  easing: "ease-in-out-sine",
});

createApp(App)
  .use(store)
  .use(router)
  .use(VueScrollTo, {
    easing: [0.86, 0, 0.07, 1],
    duration: 1500,
  })
  .mount("#app");
