<template>
  <nav class="py-4 text-white px-8 ssm:px-10 w-full flexCenter flex-col">
    <div
      class="nav__container w-full px-6 lgLandscape:px-36 xl:px-48 mx-auto my-0 flexCenter flex-col"
    >
      <div
        class="grid grid-cols-1 md:grid-cols-3 md:gap-4 justify-between items-center w-full py-2 ssm:py-6"
      >
        <div
          class="address__container flexCenter order-2 md:order-none pt-8 pb-2 md:py-0"
          data-aos="fade-down"
          data-aos-delay="400"
          data-aos-offset="-100"
        >
          <div class="icon mr-6 hidden md:block">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              class="w-full h-full fill-current hover:text-green"
            >
              <path
                d="M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z"
              />
            </svg>
          </div>
          <a
            class="address hover:text-green text-lg md:text-base lg:text-lg"
            href="https://goo.gl/maps/ENCqowpYxAhEJDy8A"
            target="_blank"
            rel="noopener"
            >Śliwkowa 134 Smolec
          </a>
        </div>
        <div
          class="logo__container flexCenter order-1 md:order-none"
          data-aos="fade-down"
          data-aos-offset="-100"
        >
          <img
            src="../assets/images/logo.png"
            class="unselectable w-36 md:w-48 max-h-20"
            width="192"
            height="70"
            alt="logo"
          />
        </div>
        <div
          class="phone__container flexCenter flex-row-reverse md:flex-row order-3 md:order-none"
          data-aos="fade-down"
          data-aos-delay="400"
          data-aos-offset="-100"
        >
          <a
            class="phone hover:text-green text-lg md:text-base lg:text-lg"
            href="tel:0048510400616"
            rel="noopener"
            >+48 510 400 616</a
          >
          <div class="icon mr-6 md:ml-6 hidden md:block">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              class="w-full h-full fill-current hover:text-green"
            >
              <path
                d="M14.89 23.654c-7.367 3.367-18.802-18.86-11.601-22.615l2.107-1.039 3.492 6.817-2.082 1.026c-2.189 1.174 2.37 10.08 4.609 8.994.091-.041 2.057-1.007 2.064-1.011l3.521 6.795c-.008.004-1.989.978-2.11 1.033zm-1.538-13.409l2.917.87c.223-.747.16-1.579-.24-2.317-.399-.739-1.062-1.247-1.808-1.469l-.869 2.916zm1.804-6.058c1.551.462 2.926 1.516 3.756 3.051.831 1.536.96 3.263.498 4.813l-1.795-.535c.325-1.091.233-2.306-.352-3.387-.583-1.081-1.551-1.822-2.643-2.146l.536-1.796zm.95-3.187c2.365.705 4.463 2.312 5.729 4.656 1.269 2.343 1.466 4.978.761 7.344l-1.84-.548c.564-1.895.406-4.006-.608-5.882-1.016-1.877-2.696-3.165-4.591-3.729l.549-1.841z"
              />
            </svg>
          </div>
        </div>
      </div>
      <div
        class="menu__container w-full max-w-screen-2xl mx-auto py-2 ssm:mx-10 md:mx-20 md:border-t-2 md:border-b-2 border-gray-200 border-opacity-20"
        data-aos="fade-up"
        data-aos-delay="800"
      >
        <ul
          class="menu__items--container flex justify-center flex-col md:flex-row"
        >
          <li
            class="menu__item mx-6 py-1 md:py-0 xl:mx-12 text-lg md:text-base lg:text-lg cursor-pointer hover:text-green text-center"
            v-for="(item, index) in menuItems"
            :class="{ active: currentCategoryIndex == index }"
            :key="item"
            @click="handleSlide(index)"
          >
            {{ item }}
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "StaticNav",
  props: {
    menuItems: Array,
    currentCategoryIndex: Number,
    swiperRef: Object
  },
  setup(props) {
    const handleSlide = (index: number) => {
      props.swiperRef?.slideTo(index, 600);
    };

    return { handleSlide };
  }
});
</script>

<style lang="scss">
@import "@/assets/styles/global.scss";

.icon {
  @apply w-6 h-6 flex justify-center items-center;
}

.active {
  @apply text-green;
  &:hover {
    color: gray;
  }
}

.flexCenter {
  @apply flex justify-center items-center;
}

.menu__item {
  position: relative;
  // &:not(last-of-type):after {
  //   content: " ";
  //   // position: absolute;
  //   letter-spacing: 1em;
  //   // top: 5px;
  //   width: 4px;
  //   height: 4px;
  //   background-color: black;
  //   display: inline-block;
  //   border-radius: 45px;
  //   vertical-align: middle;
  // }
}

@media (min-width: 1596px) {
  .nav__container {
    padding-left: 20rem !important;
    padding-right: 20rem !important;
  }
}
</style>
