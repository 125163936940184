
import { defineComponent } from "vue";

interface FooterItem {
  title: string;
  subItems: { title: string; action?: string; targetType?: string }[];
}

export default defineComponent({
  name: "Footer",
  setup() {
    const footerItems: FooterItem[] = [
      {
        title: "Nawigacja",
        subItems: [
          {
            title: "Centrum Tenisowe",
            action: "#"
          },
          {
            title: "Restauracja & Pub",
            action: "#"
          },
          {
            title: "Pizzeria & Dowozy",
            action: "#"
          }
        ]
      },
      {
        title: "Lokalizacja",
        subItems: [
          {
            title: "Tenis Planet Restauracja",
            action: "https://goo.gl/maps/ENCqowpYxAhEJDy8A",
            targetType: "_blank"
          },
          {
            title: "ul. Śliwkowa 134",
            action: "https://goo.gl/maps/ENCqowpYxAhEJDy8A",
            targetType: "_blank"
          },
          {
            title: "55-080 Smolec",
            action: "https://goo.gl/maps/ENCqowpYxAhEJDy8A",
            targetType: "_blank"
          }
        ]
      },
      {
        title: "Kontakt",
        subItems: [
          {
            title: "+48 510 400 616",
            action: "tel:0048510400616"
          },
          {
            title: "biuro@tenisplanet.pl",
            action: "mailto:biuro@tenisplanet.pl"
          }
        ]
      }
    ];
    return { footerItems };
  }
});
