
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";
export default defineComponent({
  name: "PizzaOfTheMonth",
  setup() {
    const store = useStore();
    store.dispatch("fetchPizzaOfTheMonth");

    const pizzaOfTheMonth = computed(() => store.getters.pizzaOfTheMonth);
    return { pizzaOfTheMonth };
  }
});
