
import { computed, defineComponent, ref } from "vue";
import { useStore } from "vuex";

import SwiperCore, { EffectFade, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.scss";
import "swiper/components/effect-fade/effect-fade.scss";

import StaticNav from "@/components/StaticNav.vue";
import PizzaOfTheMonth from "@/components/PizzaOfTheMonth.vue";
import Testimonials from "@/components/Testimonials.vue";
import FacebookSection from "@/components/FacebookSection.vue";
import MapSection from "@/components/MapSection.vue";
import InstagramSection from "@/components/InstagramSection.vue";
import Footer from "@/components/Footer.vue";

SwiperCore.use([EffectFade, Autoplay]);

type extendedDescription = [string, string];

interface Category {
  categoryTitle: string;
  headerTitle: string;
  buttonText: string;
  images: {
    desktop: string;
    mobile: string;
  }[];
  placeholderImage: string;
  video: string;
  introduction: {
    title: string;
    subtitle: string;
    description: string[];
    image: string;
  };
  subjectSection: {
    title: string;
    subtitle: string;
    description: extendedDescription;
    buttonContent: {
      text: string;
      action: string;
    };
    image: string;
  };
}

export default defineComponent({
  name: "Home",
  components: {
    Swiper,
    SwiperSlide,
    StaticNav,
    PizzaOfTheMonth,
    Testimonials,
    FacebookSection,
    MapSection,
    InstagramSection,
    Footer
  },
  setup() {
    const store = useStore();
    const isLoading = computed(() => store.getters.isLoading);

    const currentCategoryIndex = ref(0);

    const categories: Category[] = [
      {
        categoryTitle: "Centrum Tenisowe",
        headerTitle: "Zapraszamy całą rodziną!",
        buttonText: "Rezerwuj kort",
        images: [
          {
            desktop: "bg3.webp",
            mobile: "bg3m.jpg"
          }
        ],
        video: "https://youtube.com/embed/EHDrAr19cI0",
        placeholderImage: "tenisplanet.webp",
        introduction: {
          title: "Witaj w Tenis Planet!",
          subtitle: "Tenis i inne sporty to nasza pasja.",
          description: [
            "Zapraszamy na nasz kameralny obiekt z rodzinną atmosferą. Nie chcemy się chwalić zawodnikami, trenerami. To są nieodłączne elementy każdego sportu. I każdy klub je ma. Bez nich by nie istniał."
          ],
          image: "introTenis.jpg"
        },
        subjectSection: {
          title: "Dołącz do naszej ligii",
          subtitle: "System rezerwacji kortu, zarządzania turniejami oraz ligą",
          description: [
            "Zapraszamy do korzystania z wygodnego kalendarza rezerwacji z widokiem dziennym lub tygodniowym każdego kortu w klubie.",
            "Umożliwia on podgląd rezerwacji ligowych i turniejowych. Nie należysz jeszcze do naszej ligii? Tutaj możesz to zmienić!"
          ],
          buttonContent: {
            text: "Przejdź do rezerwacji kortu",
            action: "https://app.tenis4u.pl/#/court/153"
          },
          image: "tenis4u.webp"
        }
      },
      {
        categoryTitle: "Restauracja & Pub",
        headerTitle: "Zapraszamy na coś pysznego!",
        buttonText: "Otwórz menu",
        images: [
          {
            desktop: "bg1.webp",
            mobile: "bg1m.jpg"
          }
        ],
        video: "https://www.youtube.com/embed/ZvmKBexoWls",
        placeholderImage: "tenisplanet.webp",
        introduction: {
          title: "Witaj w naszej restauracji",
          subtitle:
            "tu gdzie łączymy pasję z rodzinną atmosferą i pysznym smakiem!",
          description: [
            "Naszą misją jest, aby każdy czuł się jak u siebie w domu. Rodzina odgrywa niezwykle istotną rolę w życiu każdego człowieka. To wśród najbliższych nabywamy podstawową wiedzę i umiejętności niezbędne do funkcjonowania w rzeczywistości społecznej.",
            "Rodzice i krewni stają się przykładem dla dzieci, które, jak wiadomo nie od dziś, najszybciej uczą się poprzez obserwację i naśladownictwo dorosłych.",
            "Nierzadko z rodzinnego domu, poza podstawowymi wzorcami zachowań, wynosimy także przekazywane z pokolenia na pokolenie tradycje oraz pasje."
          ],
          image: "introRestaurant.webp"
        },
        subjectSection: {
          title: "Sprawdź",
          subtitle: "nasze menu",
          description: [
            "Tradycyjne receptury, przekazywane z pokolenia  na pokolenie, staranny dobór składników i kadra kucharzy z pasją, pozwalają częstować naszych klientów daniami o wspaniałym smaku.  ",
            "Zapraszamy do zamawiania naszej pizzy  w dostawie, a w ofercie stacjonarnej również  menu śniadań, lunchy i orzeźwiających  selekcjonowanych piw."
          ],
          buttonContent: {
            text: "Otwórz menu w formie wirtualnej książki",
            action: "http://menu.tenisplanet.pl/"
          },
          image: "salad.png"
        }
      },
      {
        categoryTitle: "Pizzeria & Dowozy",
        headerTitle: "Zapraszamy na pizzę!",
        buttonText: "Zamów z dostawą",
        images: [
          {
            desktop: "bg2.webp",
            mobile: "bg2m.jpg"
          }
        ],
        video: "https://www.youtube.com/embed/ZvmKBexoWls",
        placeholderImage: "tenisplanet.webp",
        introduction: {
          title: "Witaj w naszej pizzerii!",
          subtitle: "Spróbuj oryginalnej włoskiej pizzy.",
          description: [
            "Tradycyjne receptury, przekazywane z pokolenia na pokolenie, staranny dobór składników i kadra kucharzy z pasją, pozwalają częstować naszych klientów daniami o wspaniałym smaku."
          ],
          image: "introPizza.webp"
        },
        subjectSection: {
          title: "System zamawiania z dostawą",
          subtitle: "www.pizzasmolec.pl",
          description: [
            "Z poszanowaniem dla Państwa, w naszym menu na dowóz, poza pizzą znalazły się tylko wyselekcjonowane dania, które jesteśmy Państwu dostarczyć w tak krótkim czasie aby nie utraciły one swojego smaku i aromatu. Nie zależy nam na ilości, najważniejsza jest jakość i Państwa zadowolenie.",
            "Ze swojej strony prosimy o wyrozumiałość w sytuacjach losowych, gdyż na pewne jak korki czy inne zdarzenia drogowe nie mamy wpływu. Prosimy pamiętać iż żadna potrawa w dostawie nie będzie tak smakować jak ta podana na miejscu. Zapraszamy więc serdecznie."
          ],
          buttonContent: {
            text: "Przejdź do zamawiania",
            action: "https://pizzasmolec.pl"
          },
          image: "pizzasmolec.webp"
        }
      }
    ];

    const menuItems: string[] = categories.map(item => item.categoryTitle);

    const swiperRef = ref(null);
    const onSwiper = (swiper: any) => {
      swiper.autoplay.start();

      // resets double swiper autoplay bug
      swiper.slideNext(1000);
      swiper.slidePrev(1000);
      swiperRef.value = swiper;
    };

    const onSlideChange = (test: any) => {
      currentCategoryIndex.value = test.activeIndex;
    };

    return {
      isLoading,
      menuItems,
      currentCategoryIndex,
      categories,
      onSwiper,
      onSlideChange,
      swiperRef
    };
  }
});
