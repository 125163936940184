<template>
  <section class="swiper relative bg-white dark:bg-gray-800 w-full h-screen">
    <swiper
      @swiper="onSwiper"
      @slideChange="onSlideChange"
      :slides-per-view="1"
      :space-between="0"
      direction="horizontal"
      :grabCursor="false"
      :speed="600"
      :centeredSlides="true"
      :edgeSwipeThreshold="50"
      :resistanceRatio="0.7"
      :threshold="5"
    >
      <swiper-slide
        class="swiper-slide bg-default text-center flex items-center justify-center"
        v-for="category in categories"
        :key="category.categoryTitle"
      >
        <swiper
          :slides-per-view="1"
          :space-between="0"
          direction="horizontal"
          :grabCursor="false"
          :speed="600"
          :centeredSlides="true"
          effect="fade"
        >
          <!-- :autoplay="{ delay: 8000, disableOnInteraction: true }" -->
          <swiper-slide
            class="swiper-slide bg-default text-center flex items-center justify-center image-slide"
            v-for="slide in category.images"
            :key="slide.desktop"
            ref="slideImage"
          >
            <img
              :srcset="
                [
                  `${require(`@/assets/images/backgrounds/${slide.mobile}`)} 768w,`,
                  `${require(`@/assets/images/backgrounds/${slide.desktop}`)} 1920w`
                ].join('\n')
              "
              sizes="100vw"
              :src="require(`@/assets/images/backgrounds/${slide.mobile}`)"
              alt=""
            />
          </swiper-slide>
        </swiper>
        <div
          class="absolute z-10 top-8 md:top-12 swiper-slide__content flex flex-col items-center justify-start mx-auto w-full h-full"
        >
          <StaticNav
            :menuItems="menuItems"
            :currentCategoryIndex="currentCategoryIndex"
            :swiperRef="swiperRef"
          />

          <div
            class="hero__container max-w-lg mx-auto px-8 ssm:px-10 md:my-8 xl:my-16 2xl:my-24 md:p-10 flex items-center justify-center flex-col"
          >
            <h2
              class="text-lg xl:text-xl uppercase text-green tracking-widest text-center hidden md:block"
              data-aos="zoom-y-out"
              data-aos-delay="1200"
            >
              {{ category.categoryTitle }}
            </h2>
            <div
              class="divider"
              data-aos="zoom-y-out"
              data-aos-delay="1200"
            ></div>
            <h1
              class="text-1xl md:text-3xl font-bold md:font-light tracking-wide text-center uppercase"
              data-aos="zoom-y-out"
              data-aos-delay="1200"
            >
              {{ category.headerTitle }}
            </h1>
            <div
              class="divider last"
              data-aos="zoom-y-out"
              data-aos-delay="1200"
            ></div>
            <div
              class="button__wrapper mt-6"
              data-aos="zoom-y-out"
              data-aos-delay="1500"
              data-aos-offset="-100"
            >
              <button
                type="button"
                class="uppercase px-6 py-3 md:py-4 bg-green border-0 text-gray-900 text-sm md:text-base font-bold hover:bg-gray-900 hover:text-green"
                v-scroll-to="'.introduction'"
              >
                {{ category.buttonText }}
              </button>
            </div>
          </div>
          <div
            class="swiper__description hidden md:block"
            data-aos="zoom-y-out"
            data-aos-offset="-100"
          >
            <div class="swiper__controls">
              <div class="swiper__counter">
                <transition-group name="fade" mode="out-in">
                  <span
                    v-for="n in categories.length"
                    :key="n"
                    :class="{ active: n - 1 === currentCategoryIndex }"
                    @click="swiperRef.slideTo(n - 1, 600)"
                  >
                    0{{ n }}
                  </span>
                </transition-group>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </section>
  <section class="info__section introduction w-full">
    <section
      class="info__content"
      :key="categories[currentCategoryIndex].introduction.title"
    >
      <div class="image__wrapper">
        <img
          :src="
            require(`@/assets/images/backgrounds/introduction/${categories[currentCategoryIndex].introduction.image}`)
          "
          class="unselectable lazyload"
          alt="section.title"
        />
      </div>
      <div
        class="info__description w-full h-full flex justify-center items-center"
      >
        <div class="info__description--content">
          <h1 class="h1" data-aos="zoom-y-out" data-aos-delay="100">
            {{ categories[currentCategoryIndex].introduction.title }}
          </h1>
          <h2 class="h2" data-aos="zoom-y-out" data-aos-delay="600">
            {{ categories[currentCategoryIndex].introduction.subtitle }}
          </h2>
          <img
            v-if="currentCategoryIndex === 1"
            :src="require('@/assets/images/dividers/separator.png')"
            alt=""
          />
          <p
            v-for="(desc, i) in categories[currentCategoryIndex].introduction
              .description"
            :key="i"
            data-aos="zoom-y-out"
            data-aos-delay="800"
          >
            {{ desc }}
          </p>
        </div>
      </div>
    </section>

    <div v-if="currentCategoryIndex === 1" class="recipes">
      <div class="image__wrapper">
        <img :src="require('@/assets/images/backgrounds/bread.png')" alt="" />
      </div>
      <h2 class="text__wrapper" data-aos="zoom-y-out" data-aos-delay="100">
        Oryginalne<br />receptury
      </h2>
    </div>

    <section
      class="info__content"
      :class="currentCategoryIndex === 1 ? 'no-bg' : null"
      :key="categories[currentCategoryIndex].subjectSection.title"
    >
      <div class="image__wrapper">
        <img
          :src="
            require(`@/assets/images/backgrounds/subjectSection/${categories[currentCategoryIndex].subjectSection.image}`)
          "
          class="unselectable lazyload"
          :class="{ full__size: currentCategoryIndex === 1 }"
          alt="section.title"
        />
      </div>
      <div
        class="info__description w-full h-full flex justify-center items-center"
      >
        <div class="info__description--content">
          <h1 class="h1" data-aos="zoom-y-out" data-aos-delay="100">
            {{ categories[currentCategoryIndex].subjectSection.title }}
          </h1>
          <h2 class="h2" data-aos="zoom-y-out" data-aos-delay="600">
            {{ categories[currentCategoryIndex].subjectSection.subtitle }}
          </h2>
          <img
            v-if="currentCategoryIndex === 1"
            :src="require('@/assets/images/dividers/separator.png')"
            alt=""
          />
          <p
            data-aos="zoom-y-out"
            data-aos-delay="800"
            class="description text-base md:text-md xl:text-lg"
            v-for="(desc, i) in categories[currentCategoryIndex].subjectSection
              .description"
            :key="i"
          >
            {{ desc }}
          </p>
          <div
            class="button__wrapper mt-4 lg:mt-8 justify-center"
            data-aos="zoom-y-out"
            data-aos-delay="600"
          >
            <a
              :href="
                categories[currentCategoryIndex].subjectSection.buttonContent
                  .action
              "
              target="_blank"
              class="uppercase px-4 py-2 bg-green border-0 text-gray-900 text-sm hover:bg-gray-900 hover:text-green text-center font-bold tracking-widest"
              rel="noopener"
            >
              {{
                categories[currentCategoryIndex].subjectSection.buttonContent
                  .text
              }}
            </a>
            <a
              class="uppercase px-4 py-2 bg-white border-0 text-gray-900 text-sm hover:bg-gray-400 text-center font-bold tracking-widest flex items-center justify-items-center"
              href="/pdf/Regulamin kortów, rezerwacji, obiektu Tenis Planet Smolec 2021.pdf"
              download
              v-if="currentCategoryIndex === 0"
              ><span>Regulamin</span></a
            >
          </div>
        </div>
      </div>
    </section>
  </section>

  <section class="page__placeholder w-full bg-default video">
    <div class="video__wrapper">
      <iframe
        :src="`${categories[currentCategoryIndex].video}?controls=0`"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </section>

  <PizzaOfTheMonth v-if="isLoading || currentCategoryIndex === 2" />
  <Testimonials />
  <InstagramSection />
  <MapSection />
  <FacebookSection />
  <Footer />
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import { useStore } from "vuex";

import SwiperCore, { EffectFade, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.scss";
import "swiper/components/effect-fade/effect-fade.scss";

import StaticNav from "@/components/StaticNav.vue";
import PizzaOfTheMonth from "@/components/PizzaOfTheMonth.vue";
import Testimonials from "@/components/Testimonials.vue";
import FacebookSection from "@/components/FacebookSection.vue";
import MapSection from "@/components/MapSection.vue";
import InstagramSection from "@/components/InstagramSection.vue";
import Footer from "@/components/Footer.vue";

SwiperCore.use([EffectFade, Autoplay]);

type extendedDescription = [string, string];

interface Category {
  categoryTitle: string;
  headerTitle: string;
  buttonText: string;
  images: {
    desktop: string;
    mobile: string;
  }[];
  placeholderImage: string;
  video: string;
  introduction: {
    title: string;
    subtitle: string;
    description: string[];
    image: string;
  };
  subjectSection: {
    title: string;
    subtitle: string;
    description: extendedDescription;
    buttonContent: {
      text: string;
      action: string;
    };
    image: string;
  };
}

export default defineComponent({
  name: "Home",
  components: {
    Swiper,
    SwiperSlide,
    StaticNav,
    PizzaOfTheMonth,
    Testimonials,
    FacebookSection,
    MapSection,
    InstagramSection,
    Footer
  },
  setup() {
    const store = useStore();
    const isLoading = computed(() => store.getters.isLoading);

    const currentCategoryIndex = ref(0);

    const categories: Category[] = [
      {
        categoryTitle: "Centrum Tenisowe",
        headerTitle: "Zapraszamy całą rodziną!",
        buttonText: "Rezerwuj kort",
        images: [
          {
            desktop: "bg3.webp",
            mobile: "bg3m.jpg"
          }
        ],
        video: "https://youtube.com/embed/EHDrAr19cI0",
        placeholderImage: "tenisplanet.webp",
        introduction: {
          title: "Witaj w Tenis Planet!",
          subtitle: "Tenis i inne sporty to nasza pasja.",
          description: [
            "Zapraszamy na nasz kameralny obiekt z rodzinną atmosferą. Nie chcemy się chwalić zawodnikami, trenerami. To są nieodłączne elementy każdego sportu. I każdy klub je ma. Bez nich by nie istniał."
          ],
          image: "introTenis.jpg"
        },
        subjectSection: {
          title: "Dołącz do naszej ligii",
          subtitle: "System rezerwacji kortu, zarządzania turniejami oraz ligą",
          description: [
            "Zapraszamy do korzystania z wygodnego kalendarza rezerwacji z widokiem dziennym lub tygodniowym każdego kortu w klubie.",
            "Umożliwia on podgląd rezerwacji ligowych i turniejowych. Nie należysz jeszcze do naszej ligii? Tutaj możesz to zmienić!"
          ],
          buttonContent: {
            text: "Przejdź do rezerwacji kortu",
            action: "https://app.tenis4u.pl/#/court/153"
          },
          image: "tenis4u.webp"
        }
      },
      {
        categoryTitle: "Restauracja & Pub",
        headerTitle: "Zapraszamy na coś pysznego!",
        buttonText: "Otwórz menu",
        images: [
          {
            desktop: "bg1.webp",
            mobile: "bg1m.jpg"
          }
        ],
        video: "https://www.youtube.com/embed/ZvmKBexoWls",
        placeholderImage: "tenisplanet.webp",
        introduction: {
          title: "Witaj w naszej restauracji",
          subtitle:
            "tu gdzie łączymy pasję z rodzinną atmosferą i pysznym smakiem!",
          description: [
            "Naszą misją jest, aby każdy czuł się jak u siebie w domu. Rodzina odgrywa niezwykle istotną rolę w życiu każdego człowieka. To wśród najbliższych nabywamy podstawową wiedzę i umiejętności niezbędne do funkcjonowania w rzeczywistości społecznej.",
            "Rodzice i krewni stają się przykładem dla dzieci, które, jak wiadomo nie od dziś, najszybciej uczą się poprzez obserwację i naśladownictwo dorosłych.",
            "Nierzadko z rodzinnego domu, poza podstawowymi wzorcami zachowań, wynosimy także przekazywane z pokolenia na pokolenie tradycje oraz pasje."
          ],
          image: "introRestaurant.webp"
        },
        subjectSection: {
          title: "Sprawdź",
          subtitle: "nasze menu",
          description: [
            "Tradycyjne receptury, przekazywane z pokolenia  na pokolenie, staranny dobór składników i kadra kucharzy z pasją, pozwalają częstować naszych klientów daniami o wspaniałym smaku.  ",
            "Zapraszamy do zamawiania naszej pizzy  w dostawie, a w ofercie stacjonarnej również  menu śniadań, lunchy i orzeźwiających  selekcjonowanych piw."
          ],
          buttonContent: {
            text: "Otwórz menu w formie wirtualnej książki",
            action: "http://menu.tenisplanet.pl/"
          },
          image: "salad.png"
        }
      },
      {
        categoryTitle: "Pizzeria & Dowozy",
        headerTitle: "Zapraszamy na pizzę!",
        buttonText: "Zamów z dostawą",
        images: [
          {
            desktop: "bg2.webp",
            mobile: "bg2m.jpg"
          }
        ],
        video: "https://www.youtube.com/embed/ZvmKBexoWls",
        placeholderImage: "tenisplanet.webp",
        introduction: {
          title: "Witaj w naszej pizzerii!",
          subtitle: "Spróbuj oryginalnej włoskiej pizzy.",
          description: [
            "Tradycyjne receptury, przekazywane z pokolenia na pokolenie, staranny dobór składników i kadra kucharzy z pasją, pozwalają częstować naszych klientów daniami o wspaniałym smaku."
          ],
          image: "introPizza.webp"
        },
        subjectSection: {
          title: "System zamawiania z dostawą",
          subtitle: "www.pizzasmolec.pl",
          description: [
            "Z poszanowaniem dla Państwa, w naszym menu na dowóz, poza pizzą znalazły się tylko wyselekcjonowane dania, które jesteśmy Państwu dostarczyć w tak krótkim czasie aby nie utraciły one swojego smaku i aromatu. Nie zależy nam na ilości, najważniejsza jest jakość i Państwa zadowolenie.",
            "Ze swojej strony prosimy o wyrozumiałość w sytuacjach losowych, gdyż na pewne jak korki czy inne zdarzenia drogowe nie mamy wpływu. Prosimy pamiętać iż żadna potrawa w dostawie nie będzie tak smakować jak ta podana na miejscu. Zapraszamy więc serdecznie."
          ],
          buttonContent: {
            text: "Przejdź do zamawiania",
            action: "https://pizzasmolec.pl"
          },
          image: "pizzasmolec.webp"
        }
      }
    ];

    const menuItems: string[] = categories.map(item => item.categoryTitle);

    const swiperRef = ref(null);
    const onSwiper = (swiper: any) => {
      swiper.autoplay.start();

      // resets double swiper autoplay bug
      swiper.slideNext(1000);
      swiper.slidePrev(1000);
      swiperRef.value = swiper;
    };

    const onSlideChange = (test: any) => {
      currentCategoryIndex.value = test.activeIndex;
    };

    return {
      isLoading,
      menuItems,
      currentCategoryIndex,
      categories,
      onSwiper,
      onSlideChange,
      swiperRef
    };
  }
});
</script>

<style lang="scss">
.hero {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.divider {
  background-image: url("../assets/images/dividers/divider.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  @apply my-4 mx-auto w-36 h-12 md:w-40 md:h-16;
  &:last-of-type,
  &.last {
    @apply hidden ssm:block;
  }
}

.menu__container {
  grid-template-rows: minmax(0, 1fr) minmax(0, 0.7fr);
  @apply md:grid-rows-2;
}

.images__container {
  div:nth-of-type(odd) {
    background-image: url("../assets/images/intro1.jpg");
  }
  div:nth-of-type(even) {
    background-image: url("../assets/images/intro2.jpg");
  }

  .imageItem {
    @apply hidden md:block;
    &:first-of-type {
      @apply block;
    }
  }
}

.text__container {
  .description {
    &:last-of-type {
      @apply mt-4;
    }
  }
}

.bg-default {
  @apply bg-center bg-no-repeat bg-cover;
}

.text__container {
  p {
    @apply tracking-wider;
  }
}

.page__placeholder {
  height: 30vh;
  @media (min-width: 768px) {
    height: 50vh;
  }
}

.swiper-container,
.swiper-wrapper {
  @apply w-full h-full;
}
.swiper-container .swiper-wrapper .swiper-slide:first-of-type {
  // @apply bg-left;
  @screen md {
    @apply bg-center;
  }
}

.swiper-container .swiper-wrapper .swiper-slide .swiper-slide__content {
  .swiper__description .swiper__controls {
    @apply w-full flex items-center justify-between pt-4;

    .swiper__counter {
      @apply hidden cursor-pointer;
      @media (min-height: 960px) {
        @apply block;
      }

      span {
        transition: color 0.3s cubic-bezier(0.76, 0, 0.24, 1);
        @apply text-lg font-extrabold text-gray-400 mx-2 hover:text-gray-200;

        &.active {
          @apply text-3xl text-gray-200;
        }
      }
    }
  }
}

.image-slide {
  picture {
    height: 100%;
    width: 100vw;
  }
  img {
    height: 100%;
    width: 100vw;
    object-fit: cover;
    object-position: center bottom;
  }
}

.recipes {
  display: grid;
  grid-template: "main" 1fr / 1fr;
  align-items: center;
  justify-items: center;
  * {
    grid-area: main;
  }
  .text__wrapper {
    text-align: center;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.59);
    font-size: 2rem;
  }
  .image__wrapper {
    width: 100%;
    img {
      width: 100%;
    }
  }
}

.video__wrapper {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  :after {
    padding-top: 56.25%;
    display: block;
    content: "";
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.info__section .info__content {
  min-height: 80vh;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0.5fr 0.5fr;
  @screen md {
    min-height: 40vh;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
  }
  @apply text-gray-600 bg-white w-full items-center justify-center;
  .image__wrapper {
    grid-row: 1;
    @screen lg {
      grid-column: 2;
    }
    @apply w-full h-full relative;
    img {
      object-fit: cover;
      @apply w-full h-full;
    }
  }
  .info__description {
    .info__description--content {
      @apply p-6 py-8 md:py-16 md:px-12 lg:px-16 2xl:px-36 text-center;
      @screen xl {
        padding-top: 128px;
        padding-bottom: 128px;
      }
      h1 {
        @apply text-xl lg:text-2xl xl:text-4xl;
      }
      h2 {
        @apply mt-8;
      }
      p {
        @apply mt-2 lg:text-lg;
      }
      img {
        margin: auto;
      }
      .button__wrapper {
        @apply w-full lg:w-auto flex items-stretch;
      }
    }
  }
  &:nth-of-type(odd) {
    .info__description {
      .info__description--content {
        h1 {
          @apply text-gray-600;
        }
      }
    }
  }
  &:nth-of-type(even) {
    @apply text-gray-200 bg-gray-600 bg-center bg-cover;
    background-image: url("../assets/images/backgrounds/darkbg.jpg");
    .image__wrapper {
      img {
        @apply p-16 xl:p-28 object-contain;
        &.full__size {
          @apply p-0 object-cover;
        }
      }
    }

    &.no-bg {
      background-image: none;
      @apply text-gray-600 bg-white;
    }

    @screen lg {
      .info__description {
        grid-column: 2;
      }
      .image__wrapper {
        grid-column: 1;
      }
    }
  }
}
</style>
