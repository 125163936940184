interface State {
  isNavOpen: boolean;
  loading: boolean;
}

const state: State = {
  isNavOpen: false,
  loading: false
};

const mutations = {
  setNav(state: State, payload: boolean) {
    state.isNavOpen = payload;
  },
  setLoading(state: State, payload: boolean) {
    state.loading = payload;
  }
};

const getters = {
  isNavOpen: (state: State) => state.isNavOpen,
  isLoading: (state: State) => state.loading
};

const actions = {};

export default { state, mutations, getters, actions };
