// eslint-disable-next-line @typescript-eslint/no-var-requires
const contentful = require("contentful");

const client = process.env.VUE_APP_accessToken
  ? contentful.createClient({
      space: process.env.VUE_APP_space,
      accessToken: process.env.VUE_APP_accessToken
    })
  : null;

import PizzaOfTheMonth from "@/typings/PizzaOfTheMonth";

interface PizzaOfTheMonthState {
  pizzaOfTheMonth: PizzaOfTheMonth;
}

const state: PizzaOfTheMonthState = {
  pizzaOfTheMonth: {} as PizzaOfTheMonth
};

const mutations = {
  setPizzaOfTheMonth(state: PizzaOfTheMonthState, payload: PizzaOfTheMonth) {
    state.pizzaOfTheMonth = payload;
  }
};

const getters = {
  pizzaOfTheMonth(state: PizzaOfTheMonthState) {
    return state.pizzaOfTheMonth;
  }
};

const actions = {
  fetchPizzaOfTheMonth({
    commit,
    state
  }: {
    commit: any;
    state: PizzaOfTheMonthState;
  }) {
    if (client !== null) {
      if (Object.keys(state.pizzaOfTheMonth).length === 0) {
        commit("setLoading", true);
        client
          .getEntries({
            order: "sys.createdAt",
            /* eslint-disable @typescript-eslint/camelcase */
            content_type: "pizzaOfTheMonth"
          })
          .then((entries: { items: { fields: PizzaOfTheMonth }[] }) => {
            const retrievedItems = entries.items.map(a => a.fields);
            commit("setPizzaOfTheMonth", retrievedItems[0]);
          });
        commit("setLoading", false);
      } else {
        commit("setPizzaOfTheMonth", state.pizzaOfTheMonth);
      }
    } else {
      commit("setPizzaOfTheMonth", {
        name: "Pizza Maja",
        price: 35,
        description:
          "Creme fraiche, mozzarella, pancetta, czosnek niedźwiedzi, zielone szparagi, wiórki cytrynowe",
        image: {
          fields: {
            file: {
              url:
                "https://images.ctfassets.net/45zmo4i0p1e7/3O4yWwkDtRftLQDQJ0VBsz/3a0ea7846beea92a21768976dac0790d/maj.jpg"
            }
          }
        }
      });
    }
  }
};

export default {
  state,
  mutations,
  getters,
  actions
};
