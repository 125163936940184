import { createStore } from "vuex";
import utils from "./modules/utils";
import pizzaOfTheMonth from "./modules/pizzaOfTheMonth";

export default createStore({
  modules: {
    utils,
    pizzaOfTheMonth
  }
});
