
import { defineComponent } from "vue";
import axios from "axios";

interface ImageObject {
  mediaUrl: string;
  permalink: string;
}

export default defineComponent({
  name: "Testimonials",
  data() {
    return {
      images: [] as ImageObject[]
    };
  },
  async created() {
    const userId = process.env.VUE_APP_userId;
    const userSecret = process.env.VUE_APP_userSecret;
    const url = `https://ig.instant-tokens.com/users/0c47812d-ede8-4d2b-a4b7-6ee77f96811d/instagram/${userId}/token?userSecret=${userSecret}`;
    let token = "";

    await axios
      .get(url)
      .then(response => (token = response.data.Token))
      .catch(e => console.log(e));

    // Instafeed doesn't have any types.
    // eslint-disable @typescript-eslint/no-explicit-any
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    // eslint-disable-next-line no-undef
    const feed = new Instafeed({
      accessToken: token,
      limit: 4,
      template:
        '<a href="{{link}}" aria-label="instagram" rel="noopener" class="post" target="_blank"><img class="image" alt="{{caption}}" src="{{image}}" /></a>'
    });
    feed.run();
  }
});
