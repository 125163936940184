<template>
  <footer>
    <div class="max-w-6xl mx-auto px-4 sm:px-6">
      <div
        class="grid justify-center md:justify-start sm:grid-cols-12 gap-8 py-8 md:py-12 border-t border-gray-200"
      >
        <div
          class="sm:col-span-12 lg:col-span-3 flex flex-col items-center justify-center sm:block sm:items-start sm:justify-start text-center sm:text-left"
        >
          <div class="mb-2">
            <img
              src="../assets/images/logoBlack.svg"
              class="unselectable w-52 max-h-20 mb-4"
              width="208"
              height="70"
              alt="logoBlack"
            />
          </div>
        </div>

        <div
          class="sm:col-span-6 md:col-span-3 lg:col-span-2 flex flex-col  items-center justify-center sm:block sm:items-start sm:justify-start text-center sm:text-left"
          v-for="item in footerItems"
          :key="item.title"
        >
          <h2 class="text-gray-800 font-semibold mb-2">{{ item.title }}</h2>
          <ul class="text-md sm:text-sm">
            <li
              class="mb-2"
              v-for="(element, index) in item.subItems"
              :key="index"
            >
              <a
                :href="element.action"
                :target="element.targetType || '_self'"
                class="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out"
                >{{ element.title }}</a
              >
            </li>
          </ul>
        </div>

        <div
          class="sm:col-span-6 md:col-span-3 lg:col-span-3 flex flex-col items-center justify-center sm:block sm:items-start sm:justify-start text-center sm:text-left"
        >
          <h6 class="text-gray-800 font-semibold mb-2">Obserwuj nas!</h6>
          <p class="text-md sm:text-sm text-gray-600 mb-4">
            Bądź na bieżąco i śledź najnowsze oferty na social mediach.
          </p>
          <ul class="flex">
            <li>
              <a
                href="https://www.instagram.com/tenisplanetsmolec/"
                target="_blank"
                class="socialIcon"
                aria-label="Instagram"
                rel="noopener"
              >
                <svg
                  class="svgIcon"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="-6 -6 36 36"
                >
                  <path
                    d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"
                  />
                </svg>
              </a>
            </li>
            <li class="ml-4">
              <a
                href="https://www.youtube.com/channel/UCKD_wDLcAK9FTLmjDOAyHhQ"
                target="_blank"
                class="socialIcon"
                aria-label="Youtube"
                rel="noopener"
              >
                <svg
                  class="svgIcon items-center m-auto"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="-6 -6 36 36"
                >
                  <path
                    d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"
                  />
                </svg>
              </a>
            </li>
            <li class="ml-4">
              <a
                href="https://www.facebook.com/tenisplanetsmolec/"
                target="_blank"
                class="socialIcon"
                aria-label="Instagram"
                rel="noopener"
              >
                <svg
                  class="svgIcon"
                  viewBox="0 0 32 32"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.023 24L14 17h-3v-3h3v-2c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V14H21l-1 3h-2.72v7h-3.257z"
                  />
                </svg>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div
        class="flex items-center flex-col md:flex-row md:justify-between pt-4 pb-8 md:py-8 border-t border-gray-200"
      >
        <div class="flex items-center mb-6 md:order-1 md:ml-4 md:mb-0">
          <p class="text-md sm:text-sm text-gray-600 mr-4">
            Projekt i utrzymanie: Goldenbird
          </p>
          <a
            href="https://goldenbird.pl"
            target="_blank"
            class="flex justify-center items-center text-gray-600 hover:text-gray-900 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out"
            aria-label="Goldenbird"
            rel="noopener"
          >
            <img
              src="../assets/images/icons/goldenbird.png"
              width="48"
              height="48"
              class="w-12 h-12"
              alt="goldenbird"
            />
          </a>
        </div>
        <div class="text-md sm:text-sm text-gray-600 mr-4">
          © {{ new Date().getFullYear() }} Tenis Smolec. Wszelkie prawa
          zastrzeżone.
        </div>
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from "vue";

interface FooterItem {
  title: string;
  subItems: { title: string; action?: string; targetType?: string }[];
}

export default defineComponent({
  name: "Footer",
  setup() {
    const footerItems: FooterItem[] = [
      {
        title: "Nawigacja",
        subItems: [
          {
            title: "Centrum Tenisowe",
            action: "#"
          },
          {
            title: "Restauracja & Pub",
            action: "#"
          },
          {
            title: "Pizzeria & Dowozy",
            action: "#"
          }
        ]
      },
      {
        title: "Lokalizacja",
        subItems: [
          {
            title: "Tenis Planet Restauracja",
            action: "https://goo.gl/maps/ENCqowpYxAhEJDy8A",
            targetType: "_blank"
          },
          {
            title: "ul. Śliwkowa 134",
            action: "https://goo.gl/maps/ENCqowpYxAhEJDy8A",
            targetType: "_blank"
          },
          {
            title: "55-080 Smolec",
            action: "https://goo.gl/maps/ENCqowpYxAhEJDy8A",
            targetType: "_blank"
          }
        ]
      },
      {
        title: "Kontakt",
        subItems: [
          {
            title: "+48 510 400 616",
            action: "tel:0048510400616"
          },
          {
            title: "biuro@tenisplanet.pl",
            action: "mailto:biuro@tenisplanet.pl"
          }
        ]
      }
    ];
    return { footerItems };
  }
});
</script>

<style lang="scss">
.socialIcon {
  transition: color 0.3s cubic-bezier(0.76, 0, 0.24, 1),
    background 0.3s cubic-bezier(0.76, 0, 0.24, 1);
  @apply flex justify-center items-center text-gray-600 hover:text-gray-900 bg-white hover:bg-gray-100 rounded-full shadow transition duration-150 ease-in-out;
}
.svgIcon {
  @apply w-10 h-10 sm:w-8 sm:h-8 fill-current;
}
</style>
